<template>
   <div class="block">
      <el-pagination @size-change="handleSizeChange"
                     :current-page="searchData2.cur"
                     :page-sizes="[4, 6, 10, 12]"
                     :page-size="searchData2.size"
                     @prev-click="prevPages"
                     @next-click="nextPages"
                     @current-change='jumpers'
                     layout="total,sizes, prev, pager, next, jumper"
                     :total="total2">
      </el-pagination>
   </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
   "patient"
);
export default {
   created () {
      // this.Selects();
      // this.getPatient();
      // this.delList()
   },

   methods: {
      ...mapMutations([
         "jumper2",
         "nextPage2",
         "firstPage2",
         "lastPage2",
         "prevPage2",
         "Selects2",
         "changeSearchData2",
         "changePageSize2",
         "changeCurrentpage2",
      ]),

      ...mapActions(["getDelList"]),
      firstPages () {
         this.firstPage2();
         this.getDelList();
      },
      nextPages () {
         this.nextPage2();

         this.getDelList();
      },
      lastPages () {
         this.lastPage2();

         this.getDelList();
      },
      prevPages () {
         this.prevPage2();

         this.getDelList();
      },
      // 下拉框 选择显示条数
      handleSizeChange (val) {
         this.Selects2(val);

         this.getDelList();
      },
      //  输入页码跳转或点击跳转
      jumpers (val) {
         this.jumper2(val)
         this.getDelList()
      }
   },
   computed: {
      ...mapState(["total2", "pages", "searchData2", "delList"]),
   },
   data () {
      return {
         selects: {
            Pages: 0,
            cur: 1,
         },
      };
   },
};
</script>

<style>
.block {
   width: 100%;
   margin-top: 20px;
   text-align: center;
}
</style>
